<template>
	<v-menu nudge-right="139" open-on-hover v-model="value">
		<template v-slot:activator="{ on, attrs }">
			<v-list-item
					@mouseenter="t = true"
					@mouseleave="t = false"
					v-on="on"
					v-bind="attrs"
					:class="{activeClass: (activeGroup && activeGroup.id === item.id)}">
				<v-list-item-title>{{ item.name }}</v-list-item-title>
				<v-list-item-icon>
					<v-icon
							:color="activeGroup && activeGroup.id === item.id ? '#ffffff': 'var(--v-drawerFont-base)'">
						mdi-chevron-right
					</v-icon>
				</v-list-item-icon>
			</v-list-item>
		</template>
		<v-list>
			<template v-for="(subItem, subIndex) in item.children">
				<v-list-item
						@mouseenter="secondMouseEnter"
						:class="{activeClass: (activeItems && (activeItems.id === subItem.id))}"
						:key="'sub_' + subIndex"
						@click="activeMenu(subItem)">
					<v-list-item-title>{{ subItem.name }}</v-list-item-title>
				</v-list-item>
			</template>
		</v-list>
	</v-menu>
</template>

<script>
export default {
	name: "min-son-menu",
	props: {
		item: {
			type: Object,
			default: ()=> ({})
		},
		activeGroup: {
			type: Object,
			default: () => ({})
		},
		activeItems: {
			type: Object,
			default: () => ({})
		},
	},
	methods: {
		activeMenu(item) {
			this.$emit('activeMenu', item)
		},
		secondMouseEnter() {
			this.t = true;
			this.$emit('mouseEnterHandler')
		}
	},
	data() {
		return {
			value: false,
			t: false
		}
	},
	watch: {
		value: {
			handler(v) {
				if (!v && this.t) {
					this.$emit('closeFirstMenu', true);
					this.t = false;
				}
			}
		}
	}
}
</script>

<style scoped lang="scss">

.activeClass {
	background: var(--v-primary-base);
	border-radius: 8px;

	.v-list-item__title {
		color: var(--v-drawerActiveFont-base);
	}
}

::v-deep {
	.v-list {
		padding: 0;
	}

	.v-list-item {
		height: 56px !important;
	}

	.v-list-item__title {
		color: var(--v-drawerFont-base);
		font-size: 15px;
	}
}
</style>
