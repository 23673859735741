<template>
  <div>
    <div
      class="c-common-title c-title"
      style="
        width: 144px;
        margin: 18px 30px 0 0;
        display: inline-block;
        text-align: center;
        position: absolute;
      "
    >
      {{ title }}
    </div>

    <v-tabs
      style="margin-left: 144px; padding-right: 100px"
      background-color="appBarBackground"
      color="primary"
      :height="60"
      @change="complete"
      v-model="tabValue"
      show-arrows
    >
      <template v-for="(item, index) in filterItems">
        <div :key="index">
          <!-- <v-hover v-slot="{ hover }" open-delay="1000" close-delay="1000"> -->
          <div>
            <v-menu
              bottom
              offset-y
              :max-height="690"
              nudge-top="5"
              close-delay="500"
              ref="vgs"
              :value="trues[index]"
              :open-on-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  v-bind="attrs"
                  v-on="on"
                  v-model="tabValue"
                  style="padding-top: 18px; padding-bottom: 18px"
                  @click="aas(index)"
                >
                  <span class="c-common-title">{{
                    item.desc ? item.desc : "无"
                  }}</span>
                </v-tab>
              </template>
              <v-list>
                <v-list-item
                  v-for="(children, j) in item.children"
                  :key="j"
                  @click="transfer(children, index)"
                >
                  <v-list-item-title
                    v-if="
                      !('children' in children) || children.children.length == 0
                    "
                    >{{ children.desc }}</v-list-item-title
                  >
                  <v-menu v-else offset-x nudge-right="30" nudge-top="8">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-title
                        class="d-flex align-center"
                        @click="transfer2(children, index)"
                        style="height: 48px"
                        v-bind="attrs"
                        v-on="on"
                        >{{ children.desc }}
                        <v-icon size="24"
                          >mdi-chevron-right</v-icon
                        ></v-list-item-title
                      >
                    </template>
                    <v-hover v-slot="{ hover }">
                      <v-list>
                        <v-list-item
                          v-for="(children3, j3) in children.children"
                          :key="j3"
                          @click="transfer(children3, index)"
                        >
                          {{ children3.desc }}{{ pp(hover) }}
                        </v-list-item>
                      </v-list>
                    </v-hover>
                  </v-menu>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <!-- </v-hover> -->
        </div>
      </template>
    </v-tabs>
  </div>
</template>

<script>
export default {
  props: {
    keyname: {
      type: String,
      default: "",
    },
    params: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tabValue: 0,
      items: [],
      title: "",
      hss: false,
      trues: [],
      oindex: 0,
      group: 0,
    };
  },
  computed: {
    filterItems() {
      return this.items.filter((n) => {
        return n.group === "顶级栏目" || n.group === "标签栏";
      });
    },
  },
  methods: {
    complete(v) {
      // 判断当前点击的tabs切换，是否是一级栏目而且children长度为0
      let items = this.filterItems[v];
      if (items.children && items.children.length === 0) {
        this.title = items.desc;
        this.$store.state.header = items;
      }
    },
    // 监听下拉菜单点击
    transfer(item, index) {
      // console.log(item,index)
      if (item.children || item.children.length === 0) {
        this.title = item.desc;
        this.$store.state.header = item;
        this.tabValue = index;
        this.$set(this.trues, index, false);
        if (this.oindex !== index) {
          this.$set(this.trues, this.oindex, false);
          this.oindex = index;
        }
        console.log(this.trues[index]);
      }
    },
    transfer2(item, index) {
      // console.log(item,index)
      if (item.children || item.children.length === 0) {
        this.title = item.desc;
        this.$store.state.header = item;
        this.tabValue = index;
        // this.$set(this.trues, index, false);
        // if (this.oindex !== index) {
        //   this.$set(this.trues, this.oindex, false);
        //   this.oindex = index;
        // }
        // console.log(this.trues[index]);
      }
    },
    pp(bool) {
      // console.log(bool);
      this.hss = bool;
      return;
    },
    ptt(bool) {
      console.log(bool);
      return bool;
    },
    aas(index) {
      this.$set(this.trues, index, true);
      if (this.oindex !== index) {
        this.$set(this.trues, this.oindex, false);
        this.oindex = index;
      } else {
        this.$set(this.trues, index, false);
        Promise.resolve().then(() => {
          this.$set(this.trues, index, true);
        });
      }
      // console.log(this.trues);
    },
    locating(v = this.$route.query.value, array) {
      const grade = Number(this.$route.query.grade);
      console.log(v, grade);
      if (grade === 1) {
        for (const item of array) {
          if (item.desc === v) {
            this.manage(item, array.indexOf(item));
          }
        }
        return;
      }

      if (grade === 2) {
        for (let i = 0; i < array.length; i++) {
          for (const item of array[i].children) {
            if (item.desc === v) {
              this.manage(item, i);
            }
          }
        }
        return;
      }
      if (grade === 3) {
        for (let i = 0; i < array.length; i++) {
          for (const item of array[i].children) {
            for (const item3 of item.children) {
              console.log(item3.desc === v);
              if (item3.desc === v) {
                this.manage(item3, i);
                return;
              }
            }
          }
        }
      }
    },
    manage(item, i) {
      this.tabValue = i;
      this.$store.state.header = item;
      this.title = item.desc;
    },
    // 初始化
    initialize() {
      this.$store.state.header = null;
      this.axios
        .post(this.select_data, {
          keyname: this.keyname,
          params: this.params,
        })
        .then((res) => {
          if (res.code === this.staticVal.Code.Success) {
            this.items = res.data[0].values;
            // this.items[1].children[0].children = [1, 2];
            if (this.$route.query.value) {
              return this.locating(this.$route.query.value, this.filterItems);
            }
            this.trues = this.items.map((n) => false);
            if (this.items[0]?.children) {
              this.$store.state.header =
                this.items[0].children.length > 0
                  ? this.items[0].children[0]
                  : this.items[0];
              this.title = this.$store.state.header.desc;
            }
          }
        });
    },
  },
  watch: {
    $route() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}

.c-common-title {
  font-weight: bold;
  font-size: 18px;
}

.c-title {
  color: var(--v-sysTabTitle-base);
}

.c-url {
  color: var(--v-primary-base);
}

.v-tab span {
  color: #999999;
}

.v-tab--active span {
  color: var(--v-primary-base);
}

::v-deep {
  .v-card > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
    border-radius: unset !important;
  }

  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
    display: inline-block;
  }

  .mdi-chevron-right::before,
  .mdi-chevron-left::before {
    color: var(--v-sysTabTitle-base);
  }
}
</style>
