<template>
	<v-card>
		<v-tabs
				v-model="tabValue"
				color="primary"
				:height="60"
				background-color="appBarBackground"
		>
			<v-tab v-for="(item, index) in items" :key="index">
				<span class="c-common-title">{{ item.desc }}</span>
			</v-tab>
		</v-tabs>
	</v-card>
</template>

<script>
export default {
	name: "OneSourceTabsIndex",
	props: {
		keyname: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			tabValue: 0,
			items: []
		}
	},
	created() {
		this.axios.post(this.select_data, {keyname: this.keyname}).then((res) => {
			if (res.data[0]?.values) {
				this.items = res.data[0].values
			}
		})
	},
	watch: {
		tabValue: {
			handler(v) {
				this.$store.commit('setOneSource', this.items[v].code)
			}
		}
	},
	destroyed() {
		this.$store.commit('setOneSource', '')
	}
}
</script>

<style scoped lang="scss">
.c-common-title {
	font-weight: bold;
	font-size: 18px;
}
</style>
