<template>
	<v-form v-model="valid" ref="form">
		<v-text-field
				label="新密码"
				autocomplete="off"
				class="asterisk"
				v-model="form.newPwd"
				type="password"
				:rules="rules('新密码')"
		>
		</v-text-field>
		<v-text-field
				autocomplete="off"
				label="确认密码"
				class="asterisk"
				type="password"
				v-model="form.pwd"
				:rules="rules('确认密码')"
		>
		</v-text-field>
		<div style="margin-top: 10px"><strong>密码长度</strong></div>
		<div class="mt-2"><span>密码长度必须在8~16个字符之间。</span></div>
		<div class="mt-4"><strong>密码强度</strong></div>
		<div class="mt-2"><span>密码强度必须包含数字、小写字母、大写字母</span></div>
		<div style="text-align: center; margin-top: 40px">
			<v-btn depressed outlined color="primary" @click="$emit('closeDialog')">取消</v-btn>
			<v-btn depressed color="primary" style="margin-left: 40px" @click="confirm">确定</v-btn>
		</div>
	</v-form>
</template>

<script>
import userApi from "@/api/systemManage/user.js";
export default {
	name: "resetPassword",
	props: {
		userid: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			form: {
				newPwd: '',
				pwd: '',
				userid: ''
			},
			valid: true
		}
	},
	methods: {
		confirm() {
			if (this.$refs.form.validate()) {
				this.form.userid = this.userid;
				this.axios.post(userApi.updatePwd, this.form).then((res)=> {
					if (res.code === this.staticVal.Code.Success) {
						this.snackbar.success('密码重置成功');
						this.$emit("closeDialog");
					} else {
						this.snackbar.error(res.msg);
					}
				});
			}
		},
		rules(text) {
			return [(v => {
				if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/.test(v)) {
					return `${text}密码长度必须在8~16个字符之间，且必须包含数字、小写字母、大写字母`
				} else if (text === '确认密码' && this.form.pwd !== this.form.newPwd) {
					return `确认密码和新密码不一致！`;
				}
				return true;
			})]
		}
	}
}
</script>

<style scoped lang="scss">
span {
	font-size: 14px;
}

strong {
	font-size: 16px;
}
</style>
