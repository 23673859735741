import commonSettingApi from '@/api/systemManage/common-setting.js';

export default {
	methods: {
		// 加载通用设置
		async loadCommonSetting(login) {
			// 获取通用设置
			let commonSettingKey = 'common-setting';
			let commonJsonStr = localStorage.getItem(commonSettingKey);
			let projectid = localStorage.getItem("projectid");
			let commonJson = {};
			if (!commonJsonStr && !login) {
				let response = await this.axios.post(commonSettingApi.load, {projectId: projectid});
				if (response.code === this.staticVal.Code.Success) {
					commonJson = response.data;
					localStorage.setItem(commonSettingKey, JSON.stringify(commonJson));
				}
			}
			if (commonJsonStr) {
				commonJson = JSON.parse(commonJsonStr);
				if (commonJson.projectid === projectid || !projectid) {
					if (login) {
						this.subTitle = commonJson.subtitle;
						this.title = commonJson.title;
						if (commonJson.loginpicurl) {
							this.backgroundUrl = commonJson.loginpicurl;
						}
						if (commonJson.weblogo) {
							this.systemLogo = commonJson.weblogo;
						}
					} else {
						if (commonJson.weblogo) {
							this.systemLogo = commonJson.weblogo;
						}
						if (commonJson.webicon) {
							this.minSystemLogo = commonJson.webicon;
						}
					}
				}
			}
		}
	}
}
