<template>
	<!-- App.vue -->
	<v-app>
		<v-navigation-drawer permanent app :mini-variant="minDrawer" color="var(--v-drawerBackground-base)">
			<template v-if="minDrawer">
				<div style="padding: 13px 0 13px 17px">
					<v-img :src="minSystemLogo" height="30" width="25"></v-img>
				</div>
			</template>
			<template v-else>
				<div style="padding: 13px 0 13px 17px">
					<v-img :src="systemLogo" height="30" width="150"></v-img>
				</div>
			</template>
			<menu-tree-list :minDrawer="minDrawer" ref="tree"/>
		</v-navigation-drawer>

		<v-app-bar
				ref="barRef"
				color="var(--v-appBarBackground-base)"
				height="56"
				app
				flat
		>
			<v-icon
					size="24" class="iconfont mr-8"
					dense
					color="var(--v-primary-base)"
					@click="minDrawer = !minDrawer"
					style="margin-right: 10px;">
				iconfont icon-{{ minDrawer ? 'zhankaicelancaidan' : 'shouqicelancaidan' }}
			</v-icon>

			<!-- 自定义显示的标题 -->
			<custom-header v-model="appBarTitle" :params="titleParams"/>

			<v-spacer></v-spacer>

			<!-- 消息通知 -->
			<message/>
			<!-- 个人信息 -->
			<user-info style="margin-left: 20px"/>

			<!-- 主题设置 -->
			<!-- <setting/> -->

		</v-app-bar>

		<v-main style="padding: 0; background-color: #F6F6FA">
			<v-container fluid>
				<router-view @refreshMenu="refreshMenu"></router-view>
			</v-container>
		</v-main>
		<v-dialog v-model="dialog" width="480px" persistent>
			<div class="pa-6 white">
				<div class="d-flex justify-space-between align-center">
					<div class="d-flex align-center">
						<v-icon class="iconfont" color="#FAAD14">iconfont icon-zhuyi-fill</v-icon>
						<div class="ml-2" style="font-size: 18px; font-weight: bold; color: #000000">提示</div>
					</div>
					<v-icon @click="dialog = false" size="18" class="iconfont">iconfont icon-guanbi</v-icon>
				</div>
				<div class="mt-6" style="font-size: 14px">
					{{ licenseTips }}
				</div>
				<div class="d-flex mt-6 justify-end">
					<v-btn depressed color="primary" @click="licenseConfirm">{{ licenseText }}</v-btn>
				</div>
			</div>
		</v-dialog>
		<div v-if="!dialog && logout_to && licenseTimeCount > 0" style="position: fixed; top: 56px; right: 0; z-index: 9;">
			<div class="tips-btn">
				退出倒计时 {{ licenseTimeCount }} 秒
			</div>
		</div>
	</v-app>
</template>

<script>
import menuTreeList from "@/components/menu/menuList.vue";
// import setting from "@/components/setting/setting.vue";
import message from "@/components/message/message.vue";
import userInfo from "@/components/userInfo/userInfo.vue";
import customHeader from "@/components/header/header.vue";
import commonSetting from '@/mixin/common-setting.js';

export default {
	name: "index",
	components: {menuTreeList, userInfo, customHeader, message},
	mixins: [commonSetting],
	data() {
		return {
			minDrawer: false,
			titleParams: {},
			systemLogo: require('@/assets/logo/system-logo.svg'),
			minSystemLogo: require('@/assets/logo/min-system-logo.svg'),
			dialog: false,
			licenseTips: '',
			licenseText: '确定',
			licenseTimer: null,
			licenseTimeCount: 20,
			logout_to: false
		}
	},
	methods: {
		refreshMenu() {
			this.$refs.tree.refreshMenu();
		},
		clearTimer() {
			if (this.licenseTimer) {
				clearInterval(this.licenseTimer)
			}
		},
		logout() {
			this.$store.state.token = '';
			sessionStorage.removeItem("token")
			sessionStorage.removeItem('userid');
			sessionStorage.removeItem('permissionKey');
			sessionStorage.removeItem('permissionPathsKey');
			sessionStorage.removeItem('operatePermissionKey');
			this.$router.push({path: "/login"});
		},
		licenseConfirm() {
			if (this.logout_to) {
				this.logout()
			}
			this.dialog = false
		}
	},
	computed: {
		appBarTitle() {
			let _this = this;
			_this.titleParams = _this.$store.state.appBarTitle;
			return _this.titleParams.name;
		}
	},
	created() {
		this.axios.post('/api/users/info').then((res) => {
			if (res.code === this.staticVal.Code.Success) {
				this.licenseTips = res.data.licenseError || res.data.licenseWarn
				this.dialog = !!this.licenseTips
				this.clearTimer()
				if (res.data.licenseError) {
					this.licenseText = '退出(' + this.licenseTimeCount + '秒)'
					this.logout_to = true
					this.licenseTimer = setInterval(() => {
						this.licenseTimeCount--
						this.licenseText = '退出(' + this.licenseTimeCount + '秒)'
						if (this.licenseTimeCount === 0) {
							this.licenseConfirm()
							this.clearTimer()
						}
					}, 1000)
				}
			}
		});
	},
	mounted() {
		this.loadCommonSetting(false);
		if (document.documentElement.clientWidth < 1920) {
			this.minDrawer = true;
		}
	}
}
</script>

<style scoped lang="scss">
.theme--light.v-app-bar.v-toolbar.v-sheet {
	border-bottom: 1px solid #e2e2e2;
}

::v-deep {
	.v-list-item__title {
		font-size: 16px;
	}
}

.v-icon.v-icon::after {
	display: none;
}

.tips-btn {
	background: var(--v-primary-base);
	border-radius: 30px 0 0 30px;
	font-size: 12px;
	padding: 4px 10px;
	color: #FFFFFF;
	font-weight: bold;
}

</style>
