var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"c-common-title c-title",staticStyle:{"width":"144px","margin":"18px 30px 0 0","display":"inline-block","text-align":"center","position":"absolute"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-tabs',{staticStyle:{"margin-left":"144px","padding-right":"100px"},attrs:{"background-color":"appBarBackground","color":"primary","height":60,"show-arrows":""},on:{"change":_vm.complete},model:{value:(_vm.tabValue),callback:function ($$v) {_vm.tabValue=$$v},expression:"tabValue"}},[_vm._l((_vm.filterItems),function(item,index){return [_c('div',{key:index},[_c('div',[_c('v-menu',{ref:"vgs",refInFor:true,attrs:{"bottom":"","offset-y":"","max-height":690,"nudge-top":"5","close-delay":"500","value":_vm.trues[index],"open-on-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({staticStyle:{"padding-top":"18px","padding-bottom":"18px"},on:{"click":function($event){return _vm.aas(index)}},model:{value:(_vm.tabValue),callback:function ($$v) {_vm.tabValue=$$v},expression:"tabValue"}},'v-tab',attrs,false),on),[_c('span',{staticClass:"c-common-title"},[_vm._v(_vm._s(item.desc ? item.desc : "无"))])])]}}],null,true)},[_c('v-list',_vm._l((item.children),function(children,j){return _c('v-list-item',{key:j,on:{"click":function($event){return _vm.transfer(children, index)}}},[(
                    !('children' in children) || children.children.length == 0
                  )?_c('v-list-item-title',[_vm._v(_vm._s(children.desc))]):_c('v-menu',{attrs:{"offset-x":"","nudge-right":"30","nudge-top":"8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({staticClass:"d-flex align-center",staticStyle:{"height":"48px"},on:{"click":function($event){return _vm.transfer2(children, index)}}},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(children.desc)+" "),_c('v-icon',{attrs:{"size":"24"}},[_vm._v("mdi-chevron-right")])],1)]}}],null,true)},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-list',_vm._l((children.children),function(children3,j3){return _c('v-list-item',{key:j3,on:{"click":function($event){return _vm.transfer(children3, index)}}},[_vm._v(" "+_vm._s(children3.desc)+_vm._s(_vm.pp(hover))+" ")])}),1)]}}],null,true)})],1)],1)}),1)],1)],1)])]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }