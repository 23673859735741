<template>
	<v-list :class="minDrawer ? 'minDrawer' : ''">
		<template v-for="item in items">
			<!-- 不含子节点 -->
			<template v-if="item.children.length === 0">
				<v-tooltip :key="item.id" :disabled="!minDrawer" right nudge-left="10">
					<template v-slot:activator="{ on, attrs }">
						<v-list-item
								v-on="on"
								v-bind="attrs"
								@click="activeMenu(item)"
								:class="{activeClass: (activeItems && (activeItems.id === item.id))}">
							<v-list-item-icon>
								<v-icon
										:color="activeItems && (activeItems.id === item.id) ? '#ffffff': 'var(--v-drawerFont-base)'"
										size="24"
										class="iconfont">
									iconfont icon-{{ item.icon }}
								</v-icon>
							</v-list-item-icon>
							<v-list-item-title v-text="item.name"></v-list-item-title>
						</v-list-item>
					</template>
					<span>{{ item.name }}</span>
				</v-tooltip>
			</template>
			<!-- 含二级节点 -->
			<template v-else>
				<template v-if="minDrawer">
					<v-menu
							:key="item.id"
							nudge-right="60"
							:min-width="140"
							:max-width="140"
							ref="firstMenuRef"
							open-on-hover
					>
						<template v-slot:activator="{ on, attrs }">
							<v-list-item
									:key="item.id"
									no-action
									v-bind="attrs"
									v-on="on"
									:class="{activeClass: (activeGroup && activeGroup.id === item.id)}"
							>
								<v-list-item-icon>
									<v-icon
											:color="activeGroup && activeGroup.id === item.id ? '#ffffff': 'var(--v-drawerFont-base)'"
											class="iconfont"
											size="24">
										iconfont icon-{{ item.icon }}
									</v-icon>
								</v-list-item-icon>
							</v-list-item>
						</template>
						<min-menu
								:active-items="activeItems"
								:active-group="activeSubGroup"
								:children="item.children"
								@closeFirstMenu="closeFirstMenu"
								@activeMenu="activeMenu"/>
					</v-menu>
				</template>
				<template v-else>
					<menu-list-group
							:key="item.id"
							:active-items="activeItems"
							:active-sub-group="activeSubGroup"
							:active-group="activeGroup"
							:item="item"
							@activeMenu="activeMenu"
					/>
				</template>
			</template>
		</template>
	</v-list>
</template>

<script>
import menuListGroup from './menu-list-group.vue';
import minMenu from "./min-menu.vue";
import menuPermissions from "@/mixin/menu-permissions";

export default {
	name: "menuList",
	mixins: [menuPermissions],
	components: {menuListGroup, minMenu},
	props: {
		minDrawer: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			items: [],
			activeItems: {},
			activeKey: 'activeMenuKey',
			activeGroup: {},
			activeSubGroup: {}
		}
	},
	methods: {
		activeMenu(obj) {
			let path = obj.path;
			if (obj.children.length === 0) {
				this.activeGroup = {};
				this.activeSubGroup = {};
			}
			if (path && path !== this.$route.path) {
				this.$router.push(path);
			}
		},
		// 激活的节点默认打开
		openActiveParentNode() {
			this.items.forEach(item => {
				if (item?.children.length > 0) {
					item.children.forEach(c => {
						if (this.activeItems?.id === c.id) {
							this.activeGroup = item;
							return false;
						}
						// 此处不递归，直接二级遍历
						if (c.children.length > 0) {
							c.children.forEach(cc => {
								if (this.activeItems?.id === cc.id) {
									this.activeGroup = item;
									this.activeSubGroup = c;
									return false;
								}
							});
						}
					});
				}
			});
		},
		closeFirstMenu() {
			this.$refs.firstMenuRef.forEach((ref) => {
				ref.isActive = false;
			});
		},
		refreshMenu() {
			this.getMenusJson((items) => {
				this.items = items;
			});
		}
	},
	mounted() {
		this.getMenusJson((items) => {
			this.items = items;
			this.activeItems = JSON.parse(localStorage.getItem(this.activeKey));
			this.openActiveParentNode();
		});
	},
	watch: {
		'$store.state.activeMenu': {
			handler(v) {
				this.activeItems = v;
				this.openActiveParentNode();
			},
			immediate: true
		}
	}
}
</script>

<style scoped lang="scss">

.activeClass {
	background: var(--v-primary-base);
	border-radius: 8px;

	.v-list-item__title {
		color: var(--v-drawerActiveFont-base);
	}
}

.v-list-item__icon {
	margin: 13px 5px 0 10px !important;
}

.minDrawer {
	margin-left: -5px;
}

::v-deep {
	.mdi-chevron-down:before {
		color: #999999;
		font-size: 20px;
	}

	.v-list-item {
		height: 56px;
	}

	.v-list-item__title {
		color: var(--v-drawerFont-base);
		font-size: 16px;
	}

	.v-list-group--sub-group {
		div {
			flex-direction: row-reverse;
		}

		.v-list-item__icon:first-child {
			margin-right: 0 !important;
			margin-top: 12px;
		}
	}
}

.v-list {
	padding: 0;
}

</style>
