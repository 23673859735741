import axios from "axios";

export function WebSocketUtils(url, callback) {
	let _this = this;
	_this.webSocket = null;
	_this.getDomainName().then(domainName => {
		//判断当前浏览器是否支持WebSocket
		if ('WebSocket' in window) {
			let protocol = document.location.protocol;
			let webSocketUrl = (protocol === "http:" ? "ws://" : "wss://") + domainName + "/ws/";
			_this.webSocket = new WebSocket(webSocketUrl + url);
		} else {
			alert('not support websocket');
		}

		// 连接发送错误的回调方法
		_this.webSocket.onerror = function (evt) {
			if (callback) {
				callback("连接发生错误:" + url, evt);
			}
		}

		// 连接成功建立的回调方法
		_this.webSocket.onopen = function (evt) {
			if (callback) {
				callback("成功建立连接:" + url, evt);
			}
		}

		// 接收到消息的回调
		_this.webSocket.onmessage = function (evt) {
			if (callback) {
				callback("message", evt);
			}
		}

		// 关闭连接的回调
		_this.webSocket.onclose = function (evt) {
			if (callback) {
				callback("连接关闭:" + url, evt);
			}
		}
	});
}

/**
 * 获取后端域名
 * @author yzw
 * @date 2022/1/18 上午10:19
 */
WebSocketUtils.prototype.getDomainName = async function () {
	let domainName;
	if (process.env.NODE_ENV === "development") {
		domainName = "chengwankeji.com:9500";
	} else {
		const res = await axios.get('/serverconfig.json');
		domainName = res.data.apiurl.replace('http://', '').replace('https://', '');
	}
	return domainName;
}

/**
 * 主动关闭
 * @author yzw
 * @date 2022/1/18 上午10:19
 */
WebSocketUtils.prototype._close = function () {
	this.webSocket.close();
}

/**
 * 发送消息
 * @author yzw
 * @date 2022/1/18 上午10:19
 */
WebSocketUtils.prototype.sendMessage = function (message) {
	this.webSocket.send(message);
}

