<template>
	<div :style="{width: width}">
		<!-- 普通标题 -->
		<template v-if="params.type === 'title'">
			<span class="c-common-title c-title">{{ params.name }}</span>
		</template>
		<!-- url 可返回原来的链接 -->
		<template v-else-if="params.type === 'url'">
			<router-link class="c-common-title c-url" :to="params.path">{{ params.title }}</router-link>
		</template>
		<!-- tab -->
		<template v-else-if="params.type === 'tab'">
			<v-card>
				<v-tabs
						@change="changeTab"
						v-model="tabValue"
						color="primary"
						:height="60"
						background-color="appBarBackground"
				>
					<template v-if="params.hasTitle">
						<span class="c-common-title c-title" style="margin: 18px 30px 0 0">
							{{ params.tab[tabValue] }}
						</span>
						<v-tab
								v-for="v in params.tab"
								:key="v"
						>
							<span class="c-common-title">{{ v }}</span>
						</v-tab>
					</template>
					<template v-else>
						<v-tab
								v-for="v in params.tab"
								:key="v.path"
								:to="v.path"
						>
							<span class="c-common-title">{{ v.title }}</span>
						</v-tab>
					</template>
				</v-tabs>
			</v-card>
		</template>
		<template v-else-if="params.type === 'one-source-tab'">
			<one-source-tabs :keyname="params.keyname"/>
		</template>
		<template v-else-if="params.type === 'customTab'">
			<dynamicHeader :keyname="params.keyname" :params="params.params"></dynamicHeader>
		</template>
	</div>
</template>

<script>
import dynamicHeader from '@/components/dynamic-header/dynamic-header.vue';
import oneSourceTabs from "@/components/one-source-tabs/index.vue";

export default {
	components: {
		dynamicHeader,
		oneSourceTabs
	},
	props: {
		params: {
			type: Object,
			default: () => ({})
		}
	},
	methods: {
		initWidth() {
			this.width = (document.body.offsetWidth - 256 - 200) + 'px';
		},
		changeTab(v) {
			if (this.params.hasTitle) {
				this.$store.commit('setSecondResource', v);
			}
		}
	},
	data() {
		return {
			width: '1000px',
			tabValue: 0
		}
	},
	created() {
		this.initWidth();
		window.addEventListener("resize", this.initWidth);
	},
	destroyed() {
		window.removeEventListener("resize", this.initWidth)
	},
	watch: {
		params: {
			handler(v) {
				if (v) {
					this.tabValue = 0
					if (this.params.hasTitle) {
						this.$store.commit('setSecondResource', '')
					}
				}
			},
			deep: true
		}
	}
}
</script>

<style scoped lang="scss">

a {
	text-decoration: none;
}

.c-common-title {
	font-weight: bold;
	font-size: 18px;
}

.c-title {
	color: var(--v-sysTabTitle-base);
}

.c-url {
	color: var(--v-primary-base);
}

.v-tab span {
	color: #999999;
}

.v-tab--active span {
	color: var(--v-primary-base);
}

::v-deep {
	.v-card > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
		border-radius: unset !important;
	}

	.v-sheet.v-card:not(.v-sheet--outlined) {
		box-shadow: none;
		display: inline-block;
	}

	.mdi-chevron-right::before, .mdi-chevron-left::before {
		color: var(--v-sysTabTitle-base);
	}
}

</style>
