<template>
	<v-list>
		<template v-for="(item, index) in children">
			<template v-if="item.children.length > 0">
				<min-son-menu
						:active-group="activeGroup"
						:active-items="activeItems"
						:item="item"
						:key="index"
						@mouseEnterHandler="$parent.$parent.mouseEnterHandler()"
						@activeMenu="activeMenu"
						@closeFirstMenu="$emit('closeFirstMenu')"
				/>
			</template>
			<template v-else>
				<v-list-item
						:key="index"
						@click="activeMenu(item)"
						:class="{activeClass: (activeItems && (activeItems.id === item.id))}"
				>
					<v-list-item-title>{{ item.name }}</v-list-item-title>
				</v-list-item>
			</template>
		</template>
	</v-list>
</template>

<script>
import minSonMenu from "./min-son-menu";
export default {
	name: "min-menu",
	components: {minSonMenu},
	props: {
		children: {
			type: Array,
			default: () => []
		},
		activeItems: {
			type: Object,
			default: () => ({})
		},
		activeGroup: {
			type: Object,
			default: () => ({})
		}
	},
	methods: {
		activeMenu(item) {
			this.$emit('activeMenu', item);
		}
	}
}
</script>

<style scoped lang="scss">

.activeClass {
	background: var(--v-primary-base);
	border-radius: 8px;

	.v-list-item__title {
		color: var(--v-drawerActiveFont-base);
	}
}

::v-deep {
	.v-list {
		padding: 0;
	}

	.v-list-item {
		height: 56px !important;
	}

	.v-list-item__title {
		color: var(--v-drawerFont-base);
		font-size: 15px;
	}
}
</style>
