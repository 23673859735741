<template>
	<v-menu rounded nudge-left="165">
		<template v-slot:activator="{ on, attrs }">
			<v-badge
					:content="content"
					color="redness"
					offset-x="15"
					:value="content > 0"
					offset-y="10">
				<v-icon class="iconfont" v-on="on" v-bind="attrs">iconfont icon-tixing</v-icon>
			</v-badge>
		</template>
		<template v-if="content > 0">
			<v-list>
				<v-list-item
						@click="toRouters('orderRecord', 'mainstatus&W&1', '/order-record')"
						v-if="confirmContent > 0"
				>
					<v-list-item-title style="margin-right: 80px">待确认订单</v-list-item-title>
					<v-badge
							:content="confirmContent"
							color="redness"
							:value="confirmContent > 0"
							offset-y="8"
							offset-x="20"></v-badge>
				</v-list-item>
				<v-list-item
						@click="toRouters('applyForRefund', 'status&N&0', '/apply-for-refund')"
						v-if="refundContent > 0"
				>
					<v-list-item-title style="margin-right: 80px">待审核退款</v-list-item-title>
					<v-badge
							:content="refundContent"
							color="redness"
							:value="refundContent > 0"
							offset-y="8"
							offset-x="20"></v-badge>
				</v-list-item>
				<v-list-item
						@click="toRouters('offlineOrder', 'passRsStatus&W&0', '/offline-order')"
						v-if="offlineContent > 0"
				>
					<v-list-item-title style="margin-right: 80px">线下支付订单</v-list-item-title>
					<v-badge
							:content="offlineContent"
							color="redness"
							:value="offlineContent > 0"
							offset-y="8"
							offset-x="20"></v-badge>
				</v-list-item>
			</v-list>
		</template>
	</v-menu>
</template>

<script>
import {WebSocketUtils} from "@/utils/webSocket";

export default {
	name: "message",
	data() {
		return {
			content: 0,
			confirmContent: 0,
			refundContent: 0,
			offlineContent: 0,
			webSocket: null
		}
	},
	mounted() {
		if (this.webSocket == null) {
			const projectid = localStorage.getItem('projectid');
			this.webSocket = new WebSocketUtils("asset/" + projectid, (k, evt) => {
				if (k === 'message') {
					const data = JSON.parse(evt.data);
					this.$store.commit('setDashboard', data.indexData);
					this.refundContent = data.auditingOrder;
					this.confirmContent = data.waitOrder;
					this.offlineContent = data.passOrder;
					this.content = this.confirmContent + this.refundContent + this.offlineContent;
				}
			});
		}
	},
	methods: {
		toRouters(name, value, path) {
			if (path !== this.$route.path) {
				this.$router.push({name: name, query: {value: value}});
			}
		}
	}
}
</script>

<style scoped lang="scss">

.v-menu__content {
	top: 56px !important;
}

</style>
