<template>
	<v-list-group
			:key="item.id"
			no-action
			:value="activeGroup && activeGroup.id === item.id"
			color="var(--v-drawerFont-base)"
	>
		<template v-slot:activator>
			<v-list-item-icon>
				<v-icon color="var(--v-drawerFont-base)" class="iconfont" size="24">iconfont icon-{{ item.icon }}</v-icon>
			</v-list-item-icon>
			<v-list-item-title v-text="item.name"></v-list-item-title>
		</template>
		<template v-for="subItem in item.children">
			<template v-if="subItem.children.length === 0">
				<v-list-item
						:class="{activeClass: (activeItems && (activeItems.id === subItem.id))}"
						:key="subItem.id"
						@click="activeMenu(subItem)"
				>
					<v-list-item-title v-text="subItem.name"></v-list-item-title>
				</v-list-item>
			</template>
			<!--	含三级节点 -->
			<template v-else>
				<v-list-group
						sub-group
						prepend-icon="mdi-chevron-down"
						:key="subItem.id"
						no-action
						:value="activeSubGroup && activeSubGroup.id === subItem.id"
						color="var(--v-drawerFont-base)"
				>
					<template v-slot:activator>
						<v-list-item-title v-text="subItem.name"></v-list-item-title>
					</template>
					<v-list-item
							v-for="subItem1 in subItem.children"
							:class="{activeClass: (activeItems && (activeItems.id === subItem1.id))}"
							:key="subItem1.id"
							@click="activeMenu(subItem1)"
					>
						<v-list-item-title v-text="subItem1.name"></v-list-item-title>
					</v-list-item>
				</v-list-group>
			</template>
		</template>
	</v-list-group>
</template>

<script>
export default {
	name: "menu-list-group",
	props: {
		item: {
			type: Object,
			default: () => ({})
		},
		activeGroup: {
			type: Object,
			default: () => ({})
		},
		activeSubGroup: {
			type: Object,
			default: () => ({})
		},
		activeItems: {
			type: Object,
			default: () => ({})
		}
	},
	methods: {
		activeMenu(item) {
			this.$emit('activeMenu', item);
		}
	}
}
</script>

<style scoped lang="scss">
.activeClass {
	background: var(--v-primary-base);
	border-radius: 8px;

	.v-list-item__title {
		color: var(--v-drawerActiveFont-base);
	}
}

.v-application--is-ltr .v-list-item__icon:first-child {
	margin-right: 6px;
	margin-top: 15px;
}

::v-deep {

	.v-list-item {
		padding-left: 25px;
	}

	.v-list-group__items .v-list-item {
		padding-left: 68px !important;
		height: 48px !important;
	}

	.v-list-group--sub-group > .v-list-group__items > .v-list-item {
		padding-left: 98px !important;
	}
}

</style>
