<template>
	<div>
		<v-menu rounded>
			<template v-slot:activator="{ on, attrs }">
				<v-btn icon v-bind="attrs" v-on="on">
					<v-icon size="38" color="primary">mdi-account-circle</v-icon>
				</v-btn>
			</template>

			<v-list>
				<v-list-item @click="logout">
					<v-list-item-icon>
						<v-icon color="primary" size="24">mdi-power</v-icon>
					</v-list-item-icon>
					<v-list-item-title>注销登录</v-list-item-title>
				</v-list-item>
				<v-list-item @click="editPwd">
					<v-list-item-icon>
						<v-icon size="24" color="primary" class="iconfont">iconfont icon-mima</v-icon>
					</v-list-item-icon>
					<v-list-item-title>修改密码</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
		<exhibition-data-box v-if="dialog" :dialog.sync="dialog" :width="492" :contentHeight="406" title="重置密码">
			<reset-password :userid="userid" @closeDialog="dialog=false"/>
		</exhibition-data-box>
	</div>
</template>

<script>
import loginApi from "@/api/login.js";
import userApi from "@/api/systemManage/user.js";
import resetPassword from "../resetPassword/resetPassword.vue";
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
export default {
	name: "userInfo",
	components: {resetPassword, exhibitionDataBox},
	data() {
		return {
			userid: '',
			dialog: false
		}
	},
	methods: {
		logout() {
			this.axios.post(loginApi.logout, {}).then(() => {
				this.$store.state.token = '';
				sessionStorage.removeItem("token")
				sessionStorage.removeItem('userid');
				sessionStorage.removeItem('permissionKey');
				sessionStorage.removeItem('permissionPathsKey');
				sessionStorage.removeItem('operatePermissionKey');
				this.$router.push({path: "/login"});
			});
		},
		editPwd() {
			this.axios.post(userApi.loginUser).then((res)=> {
				if (res.code === this.staticVal.Code.Success) {
					this.userid = res.data.userid;
					this.$nextTick(()=> {
						this.dialog = true;
					});
				}
			});
		}
	}
}
</script>

<style scoped lang="scss">
.v-list-item__icon {
	margin-right: 5px !important;
}

.v-list-item__title {
	font-size: 14px;
}

.v-menu__content {
	top: 56px !important;
}

.v-btn:before {
	display: none;
}

</style>
